import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
    meta: {
      alive: true
    }
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import(/* webpackChunkName: "solution" */ '../views/solution.vue'),
    meta: {
      alive: true
    }
  },
  {
    path: '/solutiondetail',
    name: 'solutiondetail',
    component: () => import(/* webpackChunkName: "solution" */ '../views/solutiondetail.vue'),
    meta: {
      alive: true
    }
  },


  
  {
    path: '/case',
    name: 'case',
    component: () => import(/* webpackChunkName: "case" */ '../views/case.vue'),
    meta: {
      alive: true
    }
  },  {
    path: '/casedetail',
    name: 'casedetail',
    component: () => import(/* webpackChunkName: "case" */ '../views/casedetail.vue'),
    meta: {
      alive: true
    }
  },

  
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/news.vue'),
    meta: {
      alive: true
    }
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: () => import(/* webpackChunkName: "news" */ '../views/newsdetail.vue'),
    meta: {
      alive: true
    }
  },


  

  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/product.vue'),
    meta: {
      alive: true
    }
  },
  {
    path: '/productdetail',
    name: 'productdetail',
    component: () => import(/* webpackChunkName: "product" */ '../views/productdetail.vue'),
    meta: {
      alive: true
    }
  },


  
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '../views/support.vue'),
    meta: {
      alive: true
    }
  },

  
  
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import(/* webpackChunkName: "aboutus" */ '../views/aboutus.vue'),
    meta: {
      alive: true
    }
  }

]


// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
  routes
})

export default router
