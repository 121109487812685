<template>
  <div id="app">
    <!-- {{ $route.meta.alive }} -->
    <headers v-if="$route.meta.alive">
      <div :class="[$route.meta.alive ? 'main' : '']">
        <router-view />
      </div>
    </headers>
    <div v-else :class="[$route.meta.alive ? 'main' : '']">
      <router-view />
    </div>

    <!-- <div :class="[$route.meta.alive?'main':'']">
      <router-view/>
    </div> -->
    <!-- <foots  v-if="$route.meta.alive"  /> -->
  </div>
</template>
<script>
import headers from "@/components/headers.vue";
export default {
  components: { headers },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.Ptitle {
  // margin-bottom: 10px;
  font-weight: bold;
  font-size: 17px;
  font-family: PingFang SC, PingFang SC;
  color: #333333;
  .circleStatus{
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
}

.textp {
  text-align: center;
  padding: 10px 0;
}
// .difp {
//   font-weight: bold;
// }
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  min-width: 1200px;
  overflow-x: auto;
  padding: 0 !important;
}
.main {
  margin-top: 71px;
}
.sameDiv {
  margin: 0 280px;
}
.circleStatus{
  display: inline-flex;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #333;
  margin-right: 5px;
  margin-left: 30px;
}
.imgBoxWrap{
  min-height: 200px;
  background: #eaf5ff;
  padding: 20px;
 
}
.newsdetail .info img{
  width: 70%;
  margin: 0 auto;
  display: block;
  // max-width: 100%;
}

@media screen and (max-width: 1420px) {
  .sameDiv {
    margin: 0 50px !important;
  }
}

@media screen and (max-width: 1800px) {
  .sameDiv {
    margin: 0 50px !important;
  }
}
</style>
