import { render, staticRenderFns } from "./headers.vue?vue&type=template&id=35fa4462&scoped=true"
import script from "./headers.vue?vue&type=script&lang=js"
export * from "./headers.vue?vue&type=script&lang=js"
import style0 from "./headers.vue?vue&type=style&index=0&id=35fa4462&prod&lang=scss&scoped=true"
import style1 from "./headers.vue?vue&type=style&index=1&id=35fa4462&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35fa4462",
  null
  
)

export default component.exports